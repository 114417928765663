export const DIVIDER_LABEL = 'млн';

export const OrderMonitoringStatus = {
  ALL: 'Все',
  COMPLETED: 'Выполнено',
  WORK: 'В работе',
  OVERDUE: 'Просрочено',
  NOT_COMPLETED: 'Не выполнено',
  IN_PROCESS: 'В процессе',
};

export const OrderMonitoringStatusId = {
  IN_WORK: 1,
  DONE: 2,
  NOT_DONE: 3,
  OVERDUE: 4,
};

export const eventSortOptions = [
  { text: 'По умолчанию', id: null, selected: false },
  { text: 'По убыванию', id: false, selected: false },
  { text: 'По возрастанию', id: true, selected: false },
];

export const ID_TYPE_BUSINESS_ADVICE = 8;
export const ID_TYPE_BUSINESS_ADVICE_RF = 7;

export const SET_LIMIT_MOD = {
  ALL: 'all',
  ADD: 'add',
};

export const DiffPlace = {
  BALANCE_PLACE: 'diffPlaceInRussianBalance',
  EXPORT_NON_ENERGY_PLACE: 'diffPlaceInRussianExportNonEnergy',
  EXPORT_PLACE: 'diffPlaceInRussianExports',
  IMPORT_PLACE: 'diffPlaceInRussianImports',
  TRADE_TURNOVER_PLACE: 'diffPlaceInRussianTradeTurnover',
};
