<template>
  <!-- Тестовая страница мероприятия -->
  <div class="container-page">
    <div class="activity-page edit">
      <tabs ref="tabControl" v-on:select="tabSelected">
        <tab name="Основная информация" :selected="true">
          <div class="tabs-inner_container">
            <div class="main-info card">
              <!-- row -->
              <div class="card-row">
                <div class="activity-page__switch">
                  <div :class="['activity-page__switch-item', { active: !businessType }]" @click="switchType(false)">
                    МПК/РГ
                  </div>
                  <div :class="['activity-page__switch-item', { active: businessType }]" @click="switchType(true)">
                    ДС
                  </div>
                </div>
              </div>
              <div class="card-row">
                <!-- col -->
                <div v-if="businessType" class="card-row_col form-group required">
                  <p class="title-row form-label">ДС</p>
                  <ComponentInput
                    :select2Settings="select2Settings"
                    :select2Options="businessEventTypeList"
                    v-model="item.mpkBusinessTipId"
                    v-on:select2OnSelect="changeBussines"
                    type="select2"
                    required
                  />
                </div>
                <div v-else class="card-row_col">
                  <p class="title-row">МПК/РГ</p>
                  <ComponentInput
                    :select2Settings="select2Settings"
                    :select2Options="mpkFilterList"
                    v-model="item.mpkId"
                    v-on:select2OnSelect="changeMpk"
                    type="select2"
                  />
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Название мероприятия</p>
                  <ComponentInput v-model="item.name" required maxLength="500" placeholder="Введите название" />
                </div>
              </div>
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col form-group required">
                  <p class="title-row form-label">Тип мероприятия</p>
                  <ComponentInput
                    v-if="businessType"
                    :select2Settings="select2Settings"
                    :select2Options="getEventTypeListBA"
                    v-model="item.eventTypeId"
                    type="select2"
                    required
                    placeholder="Выберетие тип"
                  />
                  <ComponentInput
                    v-else
                    :select2Settings="select2Settings"
                    :select2Options="getEventTypeListMPK"
                    v-model="item.eventTypeId"
                    type="select2"
                    required
                    placeholder="Выберетие тип"
                  />
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Дата и время проведения</p>
                  <div
                    class="date-container dateCalendarContainer-leftPopup"
                    v-for="(el, n) in item.eventDatesList"
                    :key="el.date"
                  >
                    <DateTimeComponent v-model="el.date" />
                    <IconComponent v-if="n == 0 && !isReadonly" @click="addEventDate()" name="plus_btn" />
                  </div>
                </div>
              </div>
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Страна</p>
                  <ComponentInput
                    :select2Settings="select2Settings"
                    :select2Options="countryList"
                    v-model="item.countryId"
                    :disabled="item.mpkId > 0"
                    type="select2"
                    required
                  />
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Место</p>
                  <ComponentInput v-model="item.place" maxLength="500" placeholder="Введите место" />
                </div>
              </div>
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Состав документов</p>
                  <div class="form-group-checkboxes">
                    <div class="checkboxInput">
                      <input type="checkbox" class="form-check-input" v-model="item.isDocProtokol" id="isDocProtokol" />
                      <label for="isDocProtokol" class="form-check-label form-label">Протокол</label>
                    </div>

                    <div class="checkboxInput">
                      <input type="checkbox" class="form-check-input" v-model="item.isDocPovestka" id="isDocPovestka" />
                      <label for="isDocPovestka" class="form-check-label form-label"
                        >Повестка пленарного заседания</label
                      >
                    </div>

                    <div class="checkboxInput">
                      <input type="checkbox" class="form-check-input" v-model="item.isDocProject" id="isDocProject" />
                      <label for="isDocProject" class="form-check-label form-label">Проект распоряжения</label>
                    </div>
                  </div>
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Формат проведения</p>
                  <div class="form-group-checkboxes">
                    <div class="checkboxInput" v-for="t in eventConductTypeList" :key="t.id">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="item.eventConductTypesIds"
                        :value="t.id"
                        :id="'eventConductType' + t.id"
                      />
                      <label :for="'eventConductType' + t.id" class="form-check-label form-label">{{ t.text }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Разрешение регистрации</p>
                  <div class="form-group-checkboxes">
                    <div class="checkboxInput">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="item.isRegistrationPermit"
                        id="chbx_isRegistrationPermit"
                      />
                      <label for="chbx_isRegistrationPermit" class="form-check-label form-label"
                        >Регистрация разрешена</label
                      >
                    </div>
                  </div>
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Закрытие регистрации</p>
                  <div class="form-group-container">
                    <p>Регистрация закрывается за</p>
                    <div class="form-group-container flex-row">
                      <input type="text" placeholder="Введите число" v-model="item.registrationCloseBeforeInDays" />
                      <p style="padding-left: 10px; display: inline-block">дн.</p>
                    </div>
                  </div>
                  <div class="invalid-msg" v-if="v$.item.registrationCloseBeforeInDays.$error">
                    Введите корректное число
                  </div>
                </div>
              </div>
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Статус</p>
                  <ComponentInput
                    :select2Settings="select2Settings"
                    :select2Options="statusList"
                    v-model="item.statusId"
                    type="select2"
                    required
                    :disabled="isReadonly || !canEditTimeline"
                  />
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Дата создания мероприятия</p>
                  <p class="date">
                    {{ $momentFormat(item.created, dateFormat.DD_MMMM_YYYY) }}
                  </p>
                </div>
              </div>
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Публикация на портале</p>
                  <div class="form-group-checkboxes">
                    <div class="checkboxInput">
                      <input type="checkbox" class="form-check-input" v-model="item.isPublish" id="chbx_isPublish" />
                      <label for="chbx_isPublish" class="form-check-label form-label">опубликовать</label>
                    </div>
                  </div>
                </div>
                <div class="card-row_col">
                  <p class="title-row">Номер и дата распоряжения</p>
                  <ComponentInput v-model="item.orderNumberDate" maxLength="500" placeholder="Введите номер и дату" />
                </div>
              </div>
              <div class="card-row">
                <div class="card-row_col w-100">
                  <p class="title-row">Ссылка на мероприятие</p>
                  <a :href="publicUrl" target="_blank">{{ publicUrl }}</a>
                </div>
              </div>

              <!-- row -->
              <div class="card-row btns">
                <ButtonStock title="Редактировать" v-if="isReadonly && canEdit" @click="goToEdit" />
                <ButtonStock
                  btnType="secondary"
                  v-if="canCreateRequest"
                  title="Создать запрос"
                  v-on:click="openRequestForm"
                />
                <ButtonStock btnType="secondary" title="Закрыть" v-on:click="close" v-if="isReadonly" />
              </div>
              <!-- row -->
              <div class="card-row btns" v-if="!isReadonly">
                <ButtonStock btnType="secondary" title="Отменить изменения" v-on:click="close" />
                <ButtonStock title="Сохранить изменения" v-on:click="save" />
              </div>
            </div>
          </div>
        </tab>
        <tab name="Таймлайн">
          <div class="tabs-inner_container">
            <div class="main-info card">
              <!-- row -->
              <div class="card-row tasks-block">
                <div class="tasks">
                  <template v-for="(task, index) in item.mpkEventTimelines" :key="task.id">
                    <div
                      :class="{
                        green: task.statusId == 3,
                        yellow: task.statusId == 2,
                        gray: task.statusId == 1,
                      }"
                      class="tasks-item"
                    >
                      <div class="tasks-item-header" v-if="!isReadonly && canEditTimeline">
                        <p class="status">
                          {{ showStatusName(task.statusId) }}
                        </p>
                        <IconComponent @click="editTimeline(task, index)" class="icon-mode tooltip-hover" name="mode" />
                        <IconComponent @click="deleteTimeline(index)" class="icon-delete tooltip-hover" name="delete" />
                      </div>
                      <div class="arrow"></div>
                      <p class="text">{{ task.name }}</p>
                      <p class="date">
                        {{ $momentFormat(task.date, dateFormat.DD_MMMM_YYYY) }}
                      </p>
                      <div class="form-group-checkboxes" v-if="!isReadonly && canEditTimeline && task.statusId < 3">
                        <div class="checkboxInput">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            :id="'is_done_' + index"
                            v-model="task.isDone"
                          />
                          <label :for="'is_done_' + index" class="form-check-label form-label">Готово</label>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- yellow - В процессе ; gray - Задача; green - Готово -->
                  <div class="tasks-added" v-if="!isReadonly && canEditTimeline">
                    <div @click="addTimeline()" class="tasks-added_block">
                      <IconComponent name="add_box" />
                      <p>Создать новый шаг</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- row -->
              <div class="card-row btns-time-line" v-if="isReadonly">
                <ButtonStock title="Редактировать" v-if="canEdit" @click="goToEdit" />
                <ButtonStock class="cancel-btn" btnType="secondary" title="Закрыть" v-on:click="close" />
              </div>
              <!-- row -->
              <div class="card-row btns-time-line" v-if="!isReadonly">
                <ButtonStock title="Сохранить изменения" v-on:click="save" />
                <ButtonStock class="cancel-btn" btnType="secondary" title="Отменить изменения" v-on:click="close" />
              </div>
            </div>
          </div>
        </tab>
        <tab name="Запросы">
          <EventRequestTable :event-id="id" ref="requestTable" />
        </tab>
        <tab name="Участники пленарного заседания">
          <ParticipantTable :event-id="id" request-type-id="1" ref="participantsPlenary" />
        </tab>
        <tab name="Участники узкого состава">
          <ParticipantTable :event-id="id" request-type-id="2" ref="participantsCircle" />
        </tab>
        <tab name="Проект распоряжения" v-if="item.isDocProject">
          <DocEditor :initialContent="item.projectHtml" @saveDoc="onSaveProject" @exportWord="onExportProject" />
        </tab>
        <tab name="Повестка пленарного заседания" v-if="item.isDocPovestka">
          <DocEditor :initialContent="item.povestkaHtml" @saveDoc="onSavePovestka" @exportWord="onExportPovestka" />
        </tab>
        <tab name="Протокол" v-if="item.isDocProtokol">
          <DocEditor :initialContent="item.protocolHtml" @saveDoc="onSaveProtocol" @exportWord="onExportProtocol" />
        </tab>
        <tab name="Вопросы к узкому составу">
          <DocEditor :initialContent="item.questionHtml" @saveDoc="onSaveQuestion" @exportWord="onExportQuestion" />
        </tab>
        <tab name="Иные документы">
          <div class="tabs-inner_container">
            <div class="main-info card">
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col w-100">
                  <p class="title-row">Документы</p>
                  <FileMultiUpload
                    :entity="item"
                    @deleteFile="removeFile"
                    prop-name="files"
                    @uploaded="fileUploaded"
                    :readonly="isReadonly"
                    :use-checkboxes="true"
                    description="Отметьте документы для вывода в публичной части портала"
                    @checked="fileChecked"
                  />
                </div>
              </div>

              <!-- row -->
              <div class="card-row btns" v-if="isReadonly">
                <ButtonStock title="Редактировать" v-if="canEdit" @click="goToEdit" />
                <ButtonStock btnType="secondary" title="Закрыть" v-on:click="close" />
              </div>
              <!-- row -->
              <div class="card-row btns" v-if="!isReadonly">
                <ButtonStock btnType="secondary" title="Отменить изменения" v-on:click="close" />
                <ButtonStock title="Сохранить изменения" v-on:click="save" />
              </div>
            </div>
          </div>
        </tab>
      </tabs>
      <!-- Edit modal -->
      <ModalComponent :title="dialogTitle" @close="dialogEdit = false" v-model="dialogEdit" :scrollable="true">
        <EventTimelineCard v-bind:value="dialogItem" v-bind:updateValue="dialogNn" v-on:save="updateTimeline($event)" />
      </ModalComponent>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { numeric } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateTimeComponent from '@/common/components/dateTimeComponent';
  import FileMultiUpload from '@/common/components/FileMultiUpload';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/event';
  import DocEditor from '../components/DocEditor.vue';
  import EventRequestTable from '../components/EventRequestTable';
  import EventTimelineCard from '../components/EventTimelineCard.vue';
  import ParticipantTable from '../components/ParticipantTable';
  import { ID_TYPE_BUSINESS_ADVICE, ID_TYPE_BUSINESS_ADVICE_RF } from '../utils/constants';

  export default {
    name: 'EventCard',
    components: {
      ParticipantTable,
      EventRequestTable,
      tabs,
      tab,
      ButtonStock,
      IconComponent,
      ModalComponent,
      ComponentInput,
      DateTimeComponent,
      EventTimelineCard,
      FileMultiUpload,
      DocEditor,
    },
    mixins: [security],
    data() {
      return {
        id: this.$route.params.id,
        isReadonly: this.$route.params.action === 'view',
        canEditTimeline: false,
        canEdit: false,
        canCreateRequest: false,

        item: {
          mpkId: null,
          countryId: null,
          eventTypeId: null,
          isActive: true,
          eventDatesList: [{ date: null }],
          eventConductTypesIds: [],
          mpkEventTimelines: [],
          uid: null,
          files: [],
          mpkBusinessTipId: null,
        },
        select2Settings: Constants.select2Settings,
        dateFormat: Constants.DATE_FORMATS,

        mpkList: [],
        countryList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        statusList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        eventConductTypeList: [],

        statusTaskList: [
          { text: 'Новое', id: '1' },
          { text: 'В процессе', id: '2' },
          { text: 'Готово', id: '3' },
        ],

        dialogTitle: 'Редактировать шаг',
        dialogIndex: -1,
        dialogItem: null,
        dialogEdit: false,
        dialogNn: 0,
        businessType: false,
        businessEventTypeList: [],
        allType: [],
      };
    },
    methods: {
      goToEdit() {
        this.$router.push({
          name: 'EventCard',
          params: { id: this.id, action: 'edit' },
        });
      },
      addEventDate() {
        this.item.eventDatesList.push({ date: null });
      },
      loadMpk() {
        API.getMpkForEvent({}).then((response) => {
          this.mpkList = response.data ? response.data : [];
        });
      },
      changeMpk() {
        if (this.item.mpkId > 0) {
          const item = this.mpkList.find((x) => x.id == this.item.mpkId);
          this.item.countryId = item ? item.countryId : null;
          this.item.mpkBusinessTipId = null;
          this.item.eventTypeId = null;
        }
      },
      loadCard() {
        if (this.id > 0) {
          API.find(this.id)
            .then((response) => {
              this.item = response.data;
              this.item.eventDatesList = this.item.eventDatesList.map((x) => ({
                date: x,
              }));
              this.item.mpkEventTimelines = this.item.mpkEventTimelines.sort((a, b) => (a.id < b.id ? -1 : 1));

              if (!this.item.eventDatesList || this.item.eventDatesList.length == 0)
                this.item.eventDatesList = [{ date: null }];

              API.generateProtocol(this.id).then((res) => {
                this.item.protocolHtml = res.data;
              });
              API.generatePovestka(this.id).then((res) => {
                this.item.povestkaHtml = res.data;
              });

              if (!this.item.uid) {
                API.getUid().then((response) => {
                  this.item.uid = response.data;
                });
              }
              this.item.files = this.item.mpkEventDocuments.map((x) => {
                const f = x.file;
                f.checked = x.isPublished;
                return f;
              });

              if (this.item.mpkBusinessTipId) {
                this.businessType = true;
              }
            })
            .catch(() => {
              Constants.alert.fire('Ошибка', 'Не удалось загрузить данные мероприятия.', 'error');
              this.$router.push({ name: 'Events' });
            });
        } else {
          this.$router.push({ name: 'EventCardAdd' });
        }
      },
      onSaveProtocol(data) {
        API.saveProtocol(this.id, JSON.stringify(data))
          .then((res) => {
            if (res.data) Constants.alert.fire('Сохранение протокола', Constants.commonSuccess, 'success');
            else Constants.alert.fire('Сохранение протокола', Constants.commonError, 'error');
          })
          .catch(() => {
            Constants.alert.fire('Сохранение протокола', Constants.commonError, 'error');
          });
      },
      onSavePovestka(data) {
        API.savePovestka(this.id, JSON.stringify(data))
          .then((res) => {
            if (res.data) Constants.alert.fire('Сохранение повестки', Constants.commonSuccess, 'success');
            else Constants.alert.fire('Сохранение повестки', Constants.commonError, 'error');
          })
          .catch(() => {
            Constants.alert.fire('Сохранение повестки', Constants.commonError, 'error');
          });
      },
      onSaveProject(data) {
        API.saveProject(this.id, JSON.stringify(data))
          .then((res) => {
            if (res.data) Constants.alert.fire('Сохранение проекта распоряжения', Constants.commonSuccess, 'success');
            else Constants.alert.fire('Сохранение проекта распоряжения', Constants.commonError, 'error');
          })
          .catch(() => {
            Constants.alert.fire('Сохранение проекта распоряжения', Constants.commonError, 'error');
          });
      },
      onExportPovestka() {
        API.exportWordPovestka(this.id);
      },
      onExportProject() {
        API.exportWordProject(this.id);
      },
      onExportProtocol() {
        API.exportWordProtocol(this.id);
      },

      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        const itemToSave = Object.assign({}, this.item);

        if (itemToSave.mpkId == 0) {
          itemToSave.mpkId = null;
        }
        itemToSave.eventDatesList = this.item.eventDatesList.filter((x) => x.date != null).map((x) => x.date);
        itemToSave.status = null;
        itemToSave.eventType = null;
        itemToSave.country = null;
        itemToSave.mpkEventTimelines.forEach((el) => {
          el.status = null;
          if (el.isDone) el.statusId = 3;
        });
        if (itemToSave.registrationCloseBeforeInDays == '') itemToSave.registrationCloseBeforeInDays = null;

        itemToSave.mpkEventDocuments = itemToSave.files.map((x) => ({
          eventId: itemToSave.id,
          fileId: x.id,
          isPublished: x.checked || false,
        }));

        API.update(itemToSave)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && (response.data.id || this.item.id > 0)) {
              //this.$router.push({ name: 'EventCard', params: { id: response.data.id, action: 'edit' } })
              this.$router.push({ name: 'Events' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Events' });
      },

      addTimeline() {
        this.dialogNn++;
        this.dialogEdit = true;
        this.dialogIndex = -1;
        this.dialogItem = { statusId: 1 };
        this.dialogTitle = 'Создать шаг';
      },
      editTimeline(el, n) {
        this.dialogNn++;
        this.dialogIndex = n;
        this.dialogItem = Object.assign({}, el);
        this.dialogTitle = 'Редактировать шаг';
        this.dialogEdit = true;
      },
      deleteTimeline(index) {
        this.item.mpkEventTimelines.splice(index, 1);
      },
      updateTimeline(newItem) {
        if (this.dialogIndex > -1) this.item.mpkEventTimelines[this.dialogIndex] = newItem;
        else this.item.mpkEventTimelines.push(newItem);
        this.dialogEdit = false;
      },

      dragTasks() {
        const slider = document.querySelector('.tasks');
        let isDown = false;
        let startX;
        let scrollLeft;
        slider.addEventListener('mousedown', (e) => {
          isDown = true;
          slider.classList.add('active');
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 0.7; //scroll-fast
          slider.scrollLeft = scrollLeft - walk;
        });
      },

      showStatusName(statusId) {
        return this.statusTaskList.find((x) => x.id == statusId).text;
      },

      openRequestForm() {
        this.$router.push({
          name: 'EventRequestForm',
          params: { id: this.item.id, action: 'request' },
        });
      },

      tabSelected() {
        const href = this.$refs.tabControl.selectedTab.href;
        if (href === '#запросы') {
          this.$refs.requestTable.ensurePage();
        } else if (href === '#участники-пленарного-заседания') {
          this.$refs.participantsPlenary.ensurePage();
        } else if (href === '#участники-узкого-состава') {
          this.$refs.participantsCircle.ensurePage();
        }
      },

      fileUploaded(e) {
        this.item.files.push(e.data);
      },
      removeFile(e) {
        var file = this.item.files.find((x) => x.id == e.id);
        var index = this.item.files.indexOf(file);
        if (index !== -1) {
          this.item.files.splice(index, 1);
        }
      },
      fileChecked(e) {
        var file = this.item.files.find((x) => x.id == e.id);
        if (file) {
          file.checked = e.checked;
        }
      },
      onSaveQuestion(data) {
        API.saveQuestion(this.id, JSON.stringify(data))
          .then((res) => {
            if (res.data)
              Constants.alert.fire('Сохранение вопросов к узкому составу', Constants.commonSuccess, 'success');
            else Constants.alert.fire('Сохранение вопросов к узкому составу', Constants.commonError, 'error');
          })
          .catch(() => {
            Constants.alert.fire('Сохранение вопросов к узкому составу', Constants.commonError, 'error');
          });
      },
      onExportQuestion() {
        API.exportWordQuestion(this.id);
      },

      switchType(state) {
        this.businessType = state;
      },

      changeBussines() {
        if (this.item.mpkBusinessTipId > 0) {
          const item = this.businessEventTypeList.find((x) => x.id == this.item.mpkBusinessTipId);
          this.item.countryId = item ? item.countryId : null;
          this.item.mpkId = null;
          this.item.eventTypeId = null;
        }
      },
    },
    computed: {
      mpkFilterList() {
        var list = [{ id: 0, text: Constants.emptySelectOptionText, selected: true }];
        this.mpkList
          .map((x) => ({ id: x.id, text: x.name }))
          .forEach(function (el) {
            list.push(el);
          });

        return list;
      },
      publicUrl() {
        return Utils.getPublicBaseUrl() + 'events/' + this.item.uid;
      },

      getEventTypeListMPK() {
        return this.allType.filter((i) => +i.id !== ID_TYPE_BUSINESS_ADVICE && +i.id !== ID_TYPE_BUSINESS_ADVICE_RF);
      },

      getEventTypeListBA() {
        return this.allType.filter((i) => +i.id === ID_TYPE_BUSINESS_ADVICE || +i.id === ID_TYPE_BUSINESS_ADVICE_RF);
      },
    },
    created() {
      this.checkPermissions([Constants.Permissions.MpkEventsEdit, Constants.Permissions.MpkEventsAccess]);
      this.checkPermission(Constants.Permissions.MpkEventsTimelineEdit).then((response) => {
        this.canEditTimeline = response.data;
      });
      this.checkPermission(Constants.Permissions.MpkEventsEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.checkPermission(Constants.Permissions.MpkEventRequestCreate).then((response) => {
        this.canCreateRequest = response.data;
      });

      this.loadCard();

      this.loadMpk();
      Utils.loadSelectOptions('countryList', this.countryList);
      Utils.loadSelectOptions('mpkEventTypeList', this.allType);
      Utils.loadSelectOptions('mpkEventConductTypeList', this.eventConductTypeList);
      Utils.loadSelectOptions('mpkEventStatusList', this.statusList);

      API.getBusinesstipsearch()
        .then((response) => {
          if (response.data.length) {
            this.businessEventTypeList = response.data.map((i) => ({ ...i, text: i.name }));
          }
        })
        .catch((err) => {
          console.error('error', err);
        });
    },
    mounted() {
      this.dragTasks();
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {
          registrationCloseBeforeInDays: { numeric },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles/EventCard.scss';
</style>

<style lang="scss">
  .edit {
    .tabs {
      overflow-x: auto;
    }
  }
  .tasks-block {
    .ps {
      max-width: calc(100vw - 50px);
    }
  }
  .activity-page {
    &__switch {
      border-radius: 4px;
      background: $grey;
      display: inline-flex;
      padding: 4px;

      &-item {
        border-radius: 3px;
        background: transparent;
        transition: 0.3s ease;
        font: 500 15px / 15px 'Fira Sans';
        letter-spacing: -0.3px;
        color: $blue;
        padding: 8px 16px;
        cursor: pointer;

        &.active {
          background: #fff;
        }
      }
    }
  }
</style>
