<template>
  <div>
    <QuillEditor theme="snow" toolbar="essential" v-model:content="content" content-type="html" ref="docEditor" />
    <div class="card-row btns" v-if="!isReadonly">
      <ButtonStock btnType="secondary" title="Отменить изменения" v-on:click="reset" />
      <ButtonStock title="Сохранить изменения" v-on:click="save" />
      <ButtonStock title="Экспорт в Word" v-on:click="exportWord" />
    </div>
  </div>
</template>

<script>
  import ButtonStock from '../../../components/buttons/ButtonStock';

  export default {
    name: 'DocEditor',
    components: { ButtonStock },
    props: {
      initialContent: String,
    },
    emits: ['saveDoc', 'resetDoc', 'exportWord'],
    data() {
      return {
        content: this.initialContent ?? '<div style="font-family=\'Times New Roman\'"></div>',
      };
    },
    created() {},
    methods: {
      save() {
        this.$emit('saveDoc', this.content);
      },
      reset() {
        this.content = this.initialContent;
        //this.$emit('reset');
      },
      exportWord() {
        this.$emit('exportWord');
      },
    },
    watch: {
      initialContent(newValue) {
        this.content =
          newValue && newValue.length > 0 ? newValue : '<div style="font-family=\'Times New Roman\'"></div>';
        this.$refs.docEditor.setContents(this.content);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-row.btns button {
    margin-top: 10px;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
</style>
