<template>
  <div>
    <div class="mb-16 button-container">
      <div class="button-container__participant-part">
        <ButtonStock class="mr-2" v-if="canAdd" title="Добавить участника" @click="editCard({})" />
        <ButtonStock title="Направить уведомление" @click="sendNotification()" />
      </div>
      <div class="button-container__export-part">
        <ButtonStock title="Скачать подробный список" class="btn-small" @click="exportListWord" />
        <ButtonStock title="Скачать список" class="btn-small btn-export" @click="exportWord" />
      </div>
    </div>
    <div class="table-container mb-16 js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th>ФИО</th>
            <th>Организация</th>
            <th>Должность</th>
            <th>Телефон</th>
            <th v-if="requestTypeId == 1">Статус</th>
            <th v-if="canEdit"></th>
            <th v-if="canDelete"></th>
          </tr>
        </thead>
        <tbody v-if="page?.items?.length && !isLoading">
          <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item)">
            <td>{{ item.name }}</td>
            <td>{{ item.organization?.name }}</td>
            <td>{{ item.position }}</td>
            <td>{{ item.phone }}</td>
            <td v-if="requestTypeId == 1">
              <span>{{ item.statusName }}</span>
              <template v-if="canConfirm && item.statusId == 1">
                <a class="ml-2" href="javascript:" @click="confirmParticipant(item)">Подтвердить</a>
                <a class="ml-2" href="javascript:" @click="declineParticipant(item)">Отклонить</a>
              </template>
            </td>
            <td v-if="canEdit">
              <IconComponent @click="editCard(item)" name="mode" />
            </td>
            <td v-if="canDelete">
              <IconComponent @click="deleteRecord(item.id)" name="delete" />
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" colspan="6">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="6">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
  </div>
  <section>
    <ModalComponent v-model="participantForm" title="Выбор участника" @close="closeModal">
      <CommissionParticipantBlock
        v-bind:value="participant"
        :country-id="participant?.countryId"
        :participant-category-id="participant?.participantCategoryId"
        @save="saveParticipant"
        @close="closeModal"
      />
    </ModalComponent>
  </section>
</template>

<script>
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import ModalComponent from '../../../components/modals/ModalComponent';
  import EventApi from '../api/event';
  import ParticipantApi from '../api/participant';
  import CommissionParticipantBlock from './CommissionParticipantChoose';

  export default {
    name: 'ParticipantTable',
    components: {
      CommissionParticipantBlock,
      ModalComponent,
      ButtonStock,
      IconComponent,
    },
    mixins: [security],
    props: {
      eventId: Number,
      requestTypeId: Number,
    },
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          requestTypeId: this.requestTypeId,
        },
        page: {},
        isLoading: false,
        canAdd: false,
        canEdit: false,
        canDelete: false,
        participantForm: false,
        participant: {},
        canConfirm: false,
      };
    },
    created() {
      this.applyPermissions();
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;
        EventApi.getParticipants(this.eventId, this.request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error?.response?.data);
          });
      },
      editCard(item) {
        this.participant = item;
        this.participantForm = true;
      },
      saveParticipant(data) {
        if (this.participant.id) {
          ParticipantApi.update(data)
            .then(() => {
              Constants.alert.fire('Редактирование участника', Constants.commonSuccess, 'success');
              this.loadPage(false);
              this.closeModal();
            })
            .catch((error) => {
              Constants.alert.fire(
                'Редактирование участника',
                error.response && error.response.data ? error.response.data : Constants.commonError,
                'error',
              );
              console.error(error?.response?.data);
            });
        } else {
          EventApi.addParticipant(this.eventId, this.requestTypeId, data)
            .then(() => {
              Constants.alert.fire('Добавление участника', Constants.commonSuccess, 'success');
              this.loadPage(false);
              this.closeModal();
            })
            .catch((error) => {
              Constants.alert.fire(
                'Добавление участника',
                error.response && error.response.data ? error.response.data : Constants.commonError,
                'error',
              );
              console.error(error?.response?.data);
            });
        }
      },
      closeModal() {
        this.participantForm = false;
      },
      deleteRecord(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            EventApi.deleteParticipant(this.eventId, this.requestTypeId, id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage(false);
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      applyPermissions() {
        this.canAdd = true;
        this.canEdit = true;
        this.canDelete = true;

        var profile = this.$store.state.auth?.oidc?.profile;
        if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));
        this.canConfirm = profile?.roleIds.includes(3) && this.requestTypeId == 1;
      },
      onPageChange(pageNumber, append) {
        this.request.pageNumber = pageNumber;
        this.loadPage(append);
      },
      exportWord() {
        EventApi.exportWord({ id: this.eventId, requestTypeId: this.requestTypeId });
      },
      exportListWord() {
        EventApi.exportListWord({ id: this.eventId, requestTypeId: this.requestTypeId });
      },
      confirmParticipant(item) {
        EventApi.confirmParticipantMpk(this.eventId, item.id)
          .then(() => {
            this.loadPage(false);
          })
          .catch((error) => {
            Constants.alert.fire(
              'Подтверждение участника',
              error.response && error.response.data ? error.response.data : 'Ошибка',
              'error',
            );
          });
      },
      declineParticipant(item) {
        Constants.alert.fire(Constants.confirmOptions.decline).then((res) => {
          if (res.isConfirmed) {
            EventApi.deleteParticipant(this.eventId, this.requestTypeId, item.id)
              .then(() => {
                this.loadPage(false);
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Отклонение участника',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      sendNotification() {
        EventApi.sendNotification(this.eventId, this.requestTypeId)
          .then(() => {
            Constants.alert.fire('Уведомление', 'Уведомление отправлено', 'success');
          })
          .catch(() => {
            Constants.alert.fire(
              'Уведомление',
              'Возникли проблемы при отправке уведомления. Обратитесь в техническую поддержку',
              'error',
            );
          });
      },
    },
    watch: {
      eventId: function () {
        this.loadPage(false);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  .btn-export {
    margin-left: 15px;
  }
</style>
