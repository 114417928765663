<template>
  <div class="commissionParticipiantCard">
    <div class="row">
      <div class="form-group">
        <p>Выберите участника мероприятия из списка</p>
        <ComponentInput
          :select2Settings="select2Settings"
          :select2Options="participantList"
          v-model="existId"
          v-on:select2OnSelect="changeSelect"
          type="select2"
        />
      </div>
    </div>
    <div class="row">
      <p>Или создайте новую карточку участника мероприятия</p>
      <div class="col-12" v-if="true || showPhoto">
        <div class="form-group">
          <PictureUpload v-on:pictureSelected="pictureSelected" :initialPhoto="item.picture" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group required">
          <ComponentInput v-model="item.name" label="ФИО" required maxLength="500" placeholder="Введите ФИО" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group required">
          <ComponentInput
            v-model="item.countryId"
            label="Страна"
            :select2Settings="select2Settings"
            :select2Options="countryList"
            type="select2"
            required
          />
        </div>
      </div>
      <div class="col-12">
        <ComponentInput
          v-model="item.organizationId"
          label="Организация"
          :select2Settings="select2Settings"
          :select2Options="organizations"
          type="select2"
        />
      </div>
      <div class="col-12">
        <div class="form-group required">
          <ComponentInput
            v-model="item.participantCategoryId"
            label="Категория"
            :select2Settings="select2Settings"
            :select2Options="participantCategoryList"
            type="select2"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group required">
          <ComponentInput
            v-model="item.position"
            label="Должность"
            required
            maxLength="250"
            placeholder="Введите должность"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <ComponentInput
            v-model="item.phone"
            label="Контактный телефон"
            maxLength="250"
            placeholder="Введите контактный телефон"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group required">
          <ComponentInput
            v-model="item.email"
            label="Адрес электронной почты"
            required
            maxLength="250"
            placeholder="Введите адрес электронной почты"
          />
        </div>
      </div>
      <div class="col-12">
        <label class="form-label">Информация об участнике</label>
        <div class="form-group required">
          <ComponentInput v-model="item.infoName" placeholder="название" maxLength="250" />
        </div>
        <div class="form-group required">
          <ComponentInput v-model="item.infoLink" placeholder="ссылка" maxLength="500" />
        </div>
      </div>
    </div>
    <div class="btns mb-16">
      <ButtonStock btnType="secondary" title="Не создавать" v-on:click="close" />
      <ButtonStock title="Создать карточку" v-on:click="save" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import PictureUpload from '@/common/components/PictureUpload';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import OrgApi from '../../admin/api/organization';
  import CountryApi from '../../nsi/api/country';
  import NsiApi from '../../nsi/api/nsi';
  import API from '../api/participant';

  export default {
    name: 'CommissionParticipantChoose',
    components: {
      ComponentInput,
      ButtonStock,
      PictureUpload,
    },
    props: {
      participantCategoryId: Number,
      countryId: Number,
      value: Object,
      showPhoto: {
        type: Boolean,
        default: false,
      },
      nn: Number,
    },
    data() {
      return {
        select2Settings: Constants.select2Settings,
        participantList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        organizations: [],
        item: this.value ?? {},
        existId: this.value ? this.value.id : 0,
        countryList: [],
        participantCategoryList: [],
      };
    },
    mounted() {
      this.loadDictionaries();
      this.loadParticipantList();
      this.loadOrganizations();
      if (this.existId) {
        this.changeSelect();
      }
    },
    methods: {
      loadDictionaries() {
        CountryApi.search({ pageNumber: 1, pageSize: 0 })
          .then((response) => {
            this.countryList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('NsiMpkParticipantCategory', { pageNumber: 1, pageSize: 0 })
          .then((response) => {
            this.participantCategoryList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      loadParticipantList() {
        API.search({ countryId: this.countryId, participantCategoryId: this.participantCategoryId }).then(
          (response) => {
            if (response.data && response.data.items) {
              response.data.items.forEach((i) => this.participantList.push({ id: i.id, text: i.name }));
            }
          },
        );
      },
      loadOrganizations() {
        const request = {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
          typeIds: [Constants.organizationType.foiv, Constants.organizationType.external],
        };
        OrgApi.searchShortFormat(request)
          .then((response) => {
            this.organizations = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      changeSelect() {
        //надо загрузить данные в остальные поля
        if (this.existId > 0) {
          API.find(this.existId).then((response) => {
            this.item = response.data;
          });
        } else {
          this.item = {};
        }
      },
      pictureSelected(data) {
        this.item.picture = data;
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        this.$emit('save', Object.assign({}, this.item));
      },
      close() {
        this.$emit('close');
      },
    },
    watch: {
      value: {
        handler: function () {
          this.existId = this.value ? this.value.id : 0;
          this.changeSelect();
        },
        deep: true,
      },
      nn: {
        handler: function () {
          //console.log(this.value);
          this.existId = this.value ? this.value.id : 0;
          this.item = this.value ?? {};
        },
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .commissionParticipiantCard {
    .btns {
      display: flex;
      justify-content: flex-end;
      button {
        &:not(:last-of-type) {
          margin-right: 16px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .commissionParticipiantCard {
      .btns {
        flex-wrap: wrap;
        justify-content: flex-start;
        button {
          width: 100%;
          &:not(:last-of-type) {
            margin-right: 0px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
</style>
