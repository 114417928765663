import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/mpkCommission/searchParticipant', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/mpkCommission/findParticipant?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/mpkCommission/updateParticipant?id=${data.id}`, data);
    else return axios.post(`/api/mpkCommission/createParticipant`, data);
  },
  delete: function (id) {
    return axios.delete('/api/mpkCommission/deleteParticipant?id=' + id);
  },
};
